module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx"},"extensions":[".mdx",".md"],"remarkPlugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/kenneth/Repos/typebrigade/typebrigade.com/node_modules/gatsby-remark-images","id":"e7177e1d-4e8a-5963-a44a-5450809f29a2","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/Users/kenneth/Repos/typebrigade/typebrigade.com/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1600},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/kenneth/Repos/typebrigade/typebrigade.com","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
